/* FUENTES */
@font-face {
  font-family: "Exo2-Regular";
  src: url("../fonts/Exo2-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Bold";
  src: url("../fonts/Exo2-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Black";
  src: url("../fonts/Exo2-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.ttf");
  font-display: swap;
}

/*-----------------------------------------------  NEW STYLES ----------------------------------------------------*/

table {
  /* border-spacing: 0px !important; */
  border-spacing: 0 8px !important;
}

.table-white.MuiTableContainer-root {
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
}

tr {
  height: 50px !important;
}

/* .MuiInputBase-input {
  height: 1em !important;
} */

/*-----------------------------------------------  NEW STYLES ----------------------------------------------------*/

/* .MuiOutlinedInput-root fieldset {
  border: 2px solid #1a11d1 !important;
  border-radius: 4px;
} */

.MuiButton-root {
  text-transform: initial !important;
}

.lg-modal {
  width: calc(100% - 30px);
  max-width: 1000px;
  margin-left: 15px;
  margin-right: 15px;
}

.md-modal {
  width: calc(100% - 30px);
  max-width: 800px;
  margin-left: 15px;
  margin-right: 15px;
}

.sm-modal {
  width: calc(100% - 30px);
  max-width: 600px;
  margin-left: 15px;
  margin-right: 15px;
}

/*-----------------------------------------------  NEW STYLES ----------------------------------------------------*/

.h-100 {
  height: 100% !important;
}

.p-relative {
  position: relative !important;
}

h1,
h2,
h3,
h4,
h5,
strong,
b {
  font-family: "Exo2-Bold" !important;
}

.text-exo-light,
#outlined-search-label,
.MuiInputBase-input,
input,
.MuiFormLabel-root {
  font-family: "Roboto-Regular" !important;
}

.MuiButton-root.text-roboto-regular,
.text-roboto-regular {
  font-family: "Roboto-Regular" !important;
}

.text-exo-bold {
  font-family: "Roboto-Bold";
}

.text-exo-black {
  font-family: "Exo2-Black";
}

/* COLORES DE FONDO */
.back-dark-red,
.back-light-blue {
  background-color: #4545f4 !important;
}

.blue-selected {
  background-color: #e4e9fa !important;
}

.back-lightest-blue {
  background-color: #9999ff !important;
}

.back-medium-red {
  background-color: #b20000 !important;
}

.back-red,
.dot-haFallado,
.MuiTab-textColorInherit {
  background-color: #bf0811 !important;
}

.txt-ha-fallado {
  color: #bf0811 !important;
}

.back-dark-grey {
  background-color: #1a11d1 !important;
}

.bg-primary-light {
  background-color: #5240f2 !important;
}

.bg-primary {
  background-color: #311def !important;
}

.bg-primary-dark {
  background-color: #1f0dbf !important;
}

.bg-secondary-light {
  background-color: #5fcbec !important;
}

.bg-secondary {
  background-color: #1cb5e5 !important;
}

.bg-secondary-dark {
  background-color: #137fa0 !important;
}

.back-medium-grey,
.dot-no-asignado {
  background-color: #858585 !important;
  opacity: 1 !important;
}

.txt-no-asignado {
  color: #858585 !important;
}

.back-medium-grey:disabled {
  background-color: #858585 !important;
  opacity: 1 !important;
}

.back-grey-input {
  background-color: #cfcdcd !important;
}

.back-grey {
  background-color: #e6e6e6 !important;
}

.back-super-light-grey {
  background-color: #fcfcfc !important;
}

.back-light-grey {
  background-color: #e5e5e5 !important;
}

.back-light-grey-2 {
  background-color: #efefef !important;
}

.back-yellow {
  background-color: #f7ac06 !important;
}

.back-sky-blue {
  background-color: #1baefc !important;
}

.back-mega-dark-grey {
  background-color: #545454 !important;
}

.back-mega-grey {
  background-color: #cfcdcd !important;
}

.back-plomo {
  background-color: #f2f2f2 !important;
}

.back-hiper-light-grey {
  background-color: #e3e3e3 !important;
}

.back-transparent {
  background-color: transparent !important;
}

.dot-exitoso,
.activo {
  background-color: #1fc600 !important;
}

.txt-exitoso {
  color: #1fc600 !important;
}

.dot-asignado {
  background-color: #f25c22 !important;
}

.dot-aceptado {
  background-color: #d60277 !important;
}

.dot-devuelto {
  background-color: #ffd518 !important;
}

.txt-aceptado {
  color: #d60277 !important;
}

.dot-iniciado {
  background-color: #1b84bf !important;
}

.dot-en-progreso {
  background-color: #0a4d73 !important;
}

.txt-en-progreso {
  color: #0a4d73 !important;
}

.dot-rechazado {
  background-color: #cfcdcd !important;
}

.txt-rechazado {
  color: #cfcdcd !important;
}

.dot-cancelado {
  background-color: #990000 !important;
}

.txt-cancelado {
  color: #990000 !important;
}

.inactivo {
  background-color: #9f9f9f !important;
}

.bg-red {
  background-color: rgb(239, 2, 2);
}

.bg-purple {
  background-color: #b555d2 !important;
}

/* COLORES DE TEXTO */
.color-medium-red {
  color: #b20000 !important;
}

.color-dark-grey {
  color: #242422 !important;
}

.color-blue {
  color: #1b84bf !important;
}

.color-purple {
  color: #b555d2 !important;
}

.color-orange {
  color: #f25c22 !important;
}

.color-yellow {
  color: #f7ac06 !important;
}

.color-devuelto {
  color: #ffd518 !important;
}

.color-light-grey {
  color: #9f9f9f !important;
}

.color-plomo {
  color: #f2f2f2 !important;
}

.color-mega-dark-grey {
  color: #545454 !important;
}

.text-blue {
  color: #311def !important;
}

/* COLUMNAS PERSONALIZADAS */
.col-custom-95 {
  flex: 0 0 95% !important;
  width: 95% !important;
  position: relative;
}
.col-custom-5 {
  flex: 0 0 5% !important;
  width: 5% !important;
  position: relative;
}

.col-custom-90 {
  flex: 0 0 90% !important;
  width: 90% !important;
  position: relative;
}

.col-custom-80 {
  flex: 0 0 calc(79% - 0.6666666666666%) !important;
  width: calc(79% - 0.6666666666666%) !important;
  position: relative;
}

.col-custom-10 {
  flex: 0 0 10% !important;
  width: 10% !important;
  position: relative;
}

.pr-05 {
  padding-right: 5px !important;
}

a {
  text-decoration: none !important;
}

input .form-control:disabled {
  background-color: #8f8e8e !important;
}

.light-disabled {
  background-color: #d9d9d9 !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

button::-moz-focus-inner {
  border: 0 !important;
}

button {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #343434 !important;
}

label.filebutton {
  height: 36px;
  width: 55px;
  padding-left: 6px;
  padding-right: 6px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}

label.filebutton2 {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

label span input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}

table {
  /* border-collapse: separate !important; */
  /* border-spacing: 0 8px !important; */
}

.template-input:-webkit-autofill,
.template-input:-webkit-autofill:hover,
.template-input:-webkit-autofill:focus,
.template-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  font-family: "Roboto-Regular" !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 0px;
  margin-bottom: 0px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #858585;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #858585;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858585;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8c8c8c !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8c8c8c !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

/*Colores*/
.color-black {
  color: #242422 !important;
}

.color-dark-grey {
  color: #545454 !important;
}

.color-grey {
  color: #9f9f9f !important;
}

.br-4 {
  border-radius: 4px !important;
}

.login-alto {
  height: 100vh;
}

.center {
  justify-content: center;
  align-items: center;
}

.icon-eye {
  width: 70%;
}

.icon-search {
  width: 40%;
}

.icon-button {
  width: 20%;
}

.icon-accion {
  width: 25px;
}

/* Menú */
.bar-rayo {
  height: 100px !important;
  background-color: #bf0811 !important;
}

.icon-button-menu {
  width: 27px !important;
  height: 27px !important;
  top: 22px !important;
  left: 80px !important;
}

.rayo-logo-header {
  top: 26px !important;
  left: 152px !important;
  width: 206px !important;
  height: 53px !important;
  opacity: 1 !important;
}

.button-red {
  background-color: #990000 !important;
}

.button-black {
  background-color: #242422 !important;
}

.button-header {
  width: 100px !important;
  height: 35px !important;
}

.MuiButton-root.btn-cliente {
  min-width: 100px !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.text-button-header {
  color: #f2f2f2;
  font-size: 12px;
  font-family: "Roboto-Regular";
}

.form-control.input-id-grupo {
  background-color: #dad8d8 !important;
  text-align: center !important;
  border-radius: 5px !important;
  font-family: "Roboto-Regular";
  font-size: 24px;
  height: 40px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.iconos_menu {
  width: 25px !important;
  height: 25px !important;
}

.title-text {
  font-family: "Exo2-Bold";
  color: #343434;
  flex-grow: 1;
}

.line-height-1 {
  line-height: 1 !important;
}

.title-text-white {
  font-family: "Exo2-Bold";
  color: #ffffff !important;
  flex-grow: 1;
}

.title-text-blue {
  font-family: "Exo2-Bold";
  color: #311def !important;
  flex-grow: 1;
}

/* Mantenedor */
.icono-logo-rayo {
  width: 58px;
  height: 54px !important;
}

/* Mantenedor */
.icono-logo-lista {
  width: 90px;
  height: 90px !important;
}

.thead-gray {
  background-color: #9f9f9f;
}

.text-title-table {
  font-family: "Roboto-Regular";
  color: #f2f2f2;
  font-size: 22px;
}

.text-td-table {
  font-family: "Roboto-Regular";
  color: #9f9f9f;
  font-size: 20px;
}

.custom-select-small {
  font-family: "Roboto-Regular";
  border: none !important;
  font-size: 11px !important;
  height: 24px !important;
  width: 100%;
  padding: 0.2rem 0.2rem !important;
  border-radius: 5px;
  background-color: #dad8d8 !important;
  color: #000000 !important;
}

.custom-select-small:focus {
  box-shadow: none !important;
}

.custom-select {
  font-family: "Roboto-Regular";
  color: #9f9f9f;
  border: none !important;
  font-size: 0.85rem !important;
  background-color: #f2f2f2 !important;
}

.custom-select:focus {
  box-shadow: none !important;
}

.MuiAccordion-root > .MuiCollapse-container {
  background-color: #f2f2f2;
}

.modal-body > .form-row > .form-group > .input-group > .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
}

.modal-body > .form-row > .form-group > .input-group > div > .input-group-text {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
}

.modal-body > .form-row > .form-group > .form-control {
  border: none !important;
  background-color: #f2f2f2 !important;
}

.modal-body > .form-row > .form-group > .form-control:disabled {
  border: none !important;
  background-color: #8f8e8e !important;
  color: #333333 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > div
  > .input-group-text {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #dad8d8;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: none !important;
  background-color: #dad8d8;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control,
.MuiAccordionDetails-root > .form-tareas > .form-group > .form-control,
.MuiAccordionDetails-root > .form-tareas > .form-group > div > .form-control {
  border: none !important;
  background-color: #dad8d8;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control,
.MuiAccordionDetails-root > .form-tareas > .form-group > .custom-select,
.MuiAccordionDetails-root > .form-tareas > .form-group > .custom-select-small,
.MuiAccordionDetails-root > .form-tareas > .form-group > div > .form-control {
  border: none !important;
  background-color: #dad8d8 !important;
  color: #000000 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > .form-control:focus,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > .form-control:-webkit-autofill,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-group
  > .form-control:-webkit-autofill,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill:hover,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill:focus,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #dad8d8 inset !important;
  background-color: #dad8d8 !important;
  color: #000000 !important;
}

.order-id-input:focus,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill:hover,
.order-id-input:-webkit-autofill:focus,
.order-id-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #dad8d8 inset !important;
  background-color: #dad8d8 !important;
}

.order-id-input:focus,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill:hover,
.order-id-input:-webkit-autofill:focus,
.order-id-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #cfcdcd inset !important;
  background-color: #cfcdcd !important;
}

.form-control.template-input {
  background-color: #dad8d8 !important;
  color: #000000 !important;
}

.form-control-login {
  border: 1px solid #242422 !important;
}

.input-buscar {
  border: none !important;
  background-color: #efefef !important;
  border-radius: 0px !important;
}

.input-group-append.append-crear {
  width: 38px !important;
  background-color: #efefef !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-group
  > .custom-select:disabled,
> .custom-select-small:disabled,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:disabled {
  -webkit-box-shadow: 0 0 0 30px #8f8e8e inset !important;
  background-color: #8f8e8e !important;
  color: #333333 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:disabled,
.MuiAccordionDetails-root > .form-tareas > .form-group > .custom-select:disabled,
.MuiAccordionDetails-root > .form-tareas > .form-group > .custom-select-small:disabled,
.MuiAccordionDetails-root > .form-tareas > .form-group > div > .form-control:disabled {
  -webkit-box-shadow: 0 0 0 30px #8f8e8e inset !important;
  background-color: #8f8e8e !important;
  color: #333333 !important;
}

.form-control:disabled,
.form-control.template-input:disabled {
  background-color: #8f8e8e !important;
  color: #333333 !important;
}

.texto-form {
  font-family: "Roboto-Regular";
  color: #ffffff;
  margin-bottom: 0.25rem !important;
}

.texto-form-dark {
  font-family: "Roboto-Regular";
  color: #242422;
  margin-bottom: 0.25rem !important;
}

.texto-form-red {
  font-family: "Roboto-Regular";
  color: #bf0811;
  margin-bottom: 0.25rem !important;
}

.texto-form-red-light {
  font-family: "Roboto-Regular";
  color: #f8545c;
  margin-bottom: 0.25rem !important;
}

.btn-primary-rayo {
  background-color: #1cb5e5;
  color: #f2f2f2;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary-rayo {
  background-color: #4545f4 !important;
  color: #f2f2f2;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary {
  background-color: transparent !important;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  color: #000000 !important;
  border: none !important;
}

.btn-secondary-white {
  background-color: transparent !important;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  color: #fff;
  border: none !important;
}

.btn-acciones {
  background-color: #e6e6e6;
  color: black;
  border-color: #e6e6e6;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0;
}

.border-th {
  border: 2px solid #f1f1f1 !important;
}

.dropdown-item {
  border: 2px solid black !important;
  display: flex !important;
  justify-content: left !important;
  align-items: left !important;
  text-align: left !important;
}

/*SweetAlert*/
.imageSweetAlert {
  background-image: url(https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg) !important;
  width: 100px;
  height: 100px;
}

.titleSweetAlert {
  font-family: "Exo2-Bold" !important;
  color: #1a11d1 !important;
}

.textSweetAlert {
  font-family: "Roboto-Regular" !important;
  color: #343434 !important;
}

.buttonSweetAlert {
  color: #fff !important;
  background-color: #1cb5e5 !important;
  font-family: "Exo2-Bold" !important;
}

.cancelButtonSweetAlert {
  color: #fff !important;
  background-color: #242422 !important;
  font-family: "Exo2-Bold" !important;
}

.p-1-5 {
  padding: 0.3rem;
}

/* ESTILOS TABLA*/

.MuiTableHead-root tr {
  border-radius: 10px !important;
  height: 50px !important;
  margin-bottom: 10px;
}

.MuiTableHead-root tr th {
  /* background-color: #9f9f9f !important;
  color: white; */
  font-family: "Roboto-Regular";
  /* text-align: center; */
  /* padding-top: 5px;
  padding-bottom: 5px; */
  /* min-height: 80px; */
  margin-bottom: 10px !important;
}

.MuiTableBody-root tr td {
  /* background-color: #e6e6e6 !important;
  color: #545454; */
  font-family: "Roboto-Regular";
  /* text-align: center; */
  /* padding-top: 5px;
  padding-bottom: 5px; */
  min-height: 80px;
}

.MuiTableRow-root {
  border-radius: 10px !important;
}

.MuiTable-root.MuiTable-stickyHeader {
  border-top-left-radius: 10px !important;
}

.MuiTableContainer-root {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.th-left-borde {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.th-right-borde {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
/* ESTILOS TABLA*/

.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit,
.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot,
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  color: #545454;
  font-family: "Roboto-Regular";
}

.Mui-focused {
  color: #545454 !important;
}

.MuiFormControl-root {
  background-color: transparent !important;
}

/* .MuiOutlinedInput-marginDense:focus {
  border-color: #545454 !important;
} */

/* .MuiOutlinedInput-root:hover fieldset {
  border-color: #1a11d1 !important;
  border-radius: 4px;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #1a11d1 !important;
  border-radius: 4px;
} */

.MuiAccordionSummary-root
  > .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 9px;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  background-color: #1a11d1 !important;
  border-radius: 2px !important;
  padding: 9px;
  height: 36.19px !important;
}

.select-crear.select-modal.e-tarea
  > .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  background-color: #1a11d1 !important;
  border-radius: 2px !important;
  padding: 9px;
  height: 34px !important;
}

MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.detalle-input {
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 9px;
}

.MuiButtonGroup-contained {
  box-shadow: none !important;
}

.h4-modal {
  font-family: "Exo2-Bold";
  color: #b20000;
  margin-bottom: 1rem;
}

.icon-table {
  border-radius: 10px !important;
}

.modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-header,
.MuiFilledInput-underline:before {
  background-color: transparent;
  border-bottom: none !important;
}

.modal-body {
  background-color: transparent;
}

.modal-footer {
  background-color: transparent;
  border-top: none !important;
}

.modal-footer > * {
  margin: 0px !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.9) !important;
}

.swal2-container {
  z-index: 9900 !important;
}

.MuiButton-root {
  min-width: auto !important;
}

.btn-actividades {
  color: #545454 !important;
  font-family: "Roboto-Regular" !important;
  text-transform: initial !important;
}

.white-formlabel .MuiFormControlLabel-label,
.btn-actividades-activo {
  color: #ffffff !important;
  font-family: "Roboto-Regular" !important;
  text-transform: initial !important;
}

.btn-filters-map {
  background-color: #bf0811 !important;
  color: #f2f2f2 !important;
  position: absolute;
  z-index: 1;
}

.square-template {
  height: 38px;
  width: 38px;
  border-radius: 7px;
}

.borde-plomo-act {
  border-left: 3px solid #cfcdcd;
  height: 12px;
  top: -12px;
  position: absolute;
  left: 18px;
}

.modal-content {
  border: none !important;
  margin-top: 64px;
  /*border: 1px solid rgba(255, 255, 255, 0.2) !important*/
  background-color: #343434 !important;
  padding: 1rem;
}

.modal-content.modal-white {
  border: none !important;
  margin-top: 64px;
  /*border: 1px solid rgba(255, 255, 255, 0.2) !important*/
  background-color: #ffffff !important;
  padding: 1.5rem;
  border-radius: 15px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.visible {
  display: block !important;
  height: calc(100vh - 64px) !important;
}

.no-visible {
  display: none !important;
}

.select-crear-cliente > .MuiInputBase-input {
  padding: 0 0 0 !important;
  padding-right: 25px !important;
  font-family: "Roboto-Regular" !important;
  font-size: 0.85rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.02857em !important;
  line-height: 1.75 !important;
}

.MuiFilledInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: none !important;
  pointer-events: none;
}

.container-select-crear,
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.container-footer-crear {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 56px !important;
}

.container-footer-top {
  padding-top: 14px;
  padding-bottom: 0px;
  min-height: 50px !important;
}

.container-footer-y {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 60px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0 !important;
}

/* MEDIA QUERY */
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 85% !important;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 94% !important;
    margin-right: 3% !important;
    margin-left: 3% !important;
  }
}

@media (min-width: 700px) and (max-width: 1500px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 94% !important;
    margin-right: 3% !important;
    margin-left: 3% !important;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #990000; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal {
  z-index: 1301 !important;
}

.select-crear.select-modal.m-estados > .MuiInputBase-input {
  display: flex !important;
  box-sizing: inherit !important;
}

.select-crear.select-modal.m-estados.t-editar {
  background-color: #ffffff !important;
  border-radius: 5px !important;
}

.z-i-300 {
  z-index: 1201 !important;
  position: fixed !important;
}

.w-90 {
  width: 90% !important;
}

.br-5 {
  border-radius: 5px;
}

.p-25 {
  padding: 0.625rem !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.form-control:disabled,
.form-control[readonly] {
  border: none !important;
  color: #000000;
}

.MuiAutocomplete-input {
  margin-top: 3px !important;
}

.MuiAutocomplete-input::placeholder {
  color: #9f9f9f !important;
  opacity: 1; /* Firefox */
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: transparent !important;
}

.MuiAutocomplete-option[aria-selected="true"]:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.borde-red {
  border: 2px solid #b20000 !important;
}

.MuiSelect-icon {
  top: auto !important;
}

.select-crear.select-estados > .MuiFilledInput-input {
  display: flex !important;
  flex-wrap: wrap !important;
  padding-left: 0px !important;
}

.MuiFormControlLabel-label {
  font-family: "Roboto-Regular" !important;
  color: #545454 !important;
}

.modal-csv p {
  font-family: "Roboto-Regular" !important;
  color: #545454 !important;
}

.link-style {
  font-family: "Roboto-Regular" !important;
  text-decoration: underline !important;
  color: #1a11d1 !important;
  cursor: pointer;
}

.link-style-white {
  text-decoration: underline !important;
  color: #ffffff !important;
  cursor: pointer;
}

.accordionTareas .MuiAccordionSummary-content {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.MuiAccordionSummary-root.accordionTareas
  > .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  padding: 7px !important;
}

.MuiTab-root {
  padding: 6px 0px !important;
  max-width: 1000px !important;
}

.href-style {
  text-decoration: underline;
  color: #1a11d1;
  cursor: pointer;
}

.input-km .form-control {
  border: none !important;
  background-color: #f2f2f2 !important;
}

.input-km .input-group-text {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
}

.form-control {
  color: #000000 !important;
}

.MuiAutocomplete-popper {
  z-index: 1303 !important;
}

.MuiSelect-iconFilled,
.r-0 {
  right: 0px !important;
}

.ver-copiado {
  position: absolute;
  bottom: 1px;
  width: calc(100% - 28px);
  font-size: 11px !important;
  line-height: 1.2 !important;
}

.ver-copiado-gt {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 10px;
  line-height: 1.2;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 3px;
  padding: 3px;
  z-index: 99;
}

.ver-copiado-gt > label {
  margin-bottom: 0px;
}

.ver-copiado-rayo {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 10px;
  line-height: 1.2;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 3px;
  padding: 3px;
  z-index: 99;
}

.ver-copiado-rayo > label {
  margin-bottom: 0px;
}

.ver-copiado-rayo.hidden,
.ver-copiado-gt.hidden,
.ver-copiado.hidden {
  display: none !important;
}

.Layout-content-8 {
  flex-grow: 1 !important;
}

.Layout-toolbar-7 {
  display: flex;
  padding: 0px 8px;
  min-height: 64px;
  align-items: center;
  justify-content: flex-end;
}

.border-blue-btn {
  background-color: transparent !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: 2px solid #1a11d1 !important;
  height: 40px !important;
  padding: 10px !important;
  color: #212529 !important;
}

.border-blue-btn:focus {
  border: 2px solid #1a11d1 !important;
}

.border-blue-btn .MuiButton-label {
  color: #212529 !important;
  font-size: 1rem !important;
}

.border-blue-icon {
  height: 40px !important;
}

.contain-template {
  height: 50px !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.w-90-button {
  width: 90px !important;
}

.MuiFormControl-root.form-height > .MuiInputBase-root {
  height: 36.19px !important;
  font-size: 0.85rem !important;
}

.MuiFormControl-root.form-height .MuiInputBase-input,
.MuiFormControl-root.form-height .MuiSelect-outlined.MuiSelect-outlined {
  font-size: 0.85rem !important;
}

.MuiSelect-selectMenu.MuiTablePagination-select {
  font-size: 0.8rem !important;
}

.select-modal .MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}
#ingresarTemplate .MuiAccordion-rounded {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

#ingresarTemplate .MuiAccordion-rounded:first-child {
  border: none !important;
}

#ingresarTemplate .MuiAccordion-root:before {
  background-color: transparent !important;
}

#ingresarTemplate .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
#ingresarTemplate .MuiFormControlLabel-root {
  margin-bottom: 0px !important;
}

#ingresarTemplate .MuiAccordionDetails-root {
  display: block !important;
}

#ingresarTemplate .MuiFormControlLabel-root.Mui-disabled .MuiSvgIcon-root path {
  fill: #c2c2c2 !important;
}

#ingresarTemplate
  .MuiFormControlLabel-root.Mui-disabled
  .MuiFormControlLabel-label {
  color: #c2c2c2 !important;
}

.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

em {
  color: #c5c5c5;
}

.input-group-text,
.form-control,
.btn {
  font-size: 0.85rem !important;
}

.small-font .MuiTableCell-root {
  font-size: 0.75rem !important;
  line-height: 1 !important;
}

.text-white {
  color: #fff !important;
}

.outline-primary {
  border: 2px solid #1a11d1 !important;
  border-radius: 4px !important;
}

.href {
  text-decoration: underline;
  cursor: pointer;
  /* color: #1a11d1 !important; */
}

.modal {
  overflow-y: auto;
}

.d-desktop-flex {
  display: flex !important;
}

.d-desktop {
  display: block !important;
}

.d-mobile-flex,
.d-mobile {
  display: none !important;
}

@media (min-width: 0px) and (max-width: 600px) {
  .d-desktop-flex,
  .d-desktop {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }

  .d-mobile-flex {
    display: flex !important;
  }
}
