.label-login {
  font-family: "Roboto-Regular" !important;
  color: #343434;
}

.background-login {
  background-image: url("../../assets/image/image_login_1.png");
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100vh;
}

.background-login-2 {
  background-image: url("../../assets/image/image_login_2.png");
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100vh;
}

.contenedor-login {
  max-width: 500px;
  background-color: #f2f2f2;
}

.form-login {
  margin-bottom: 10%;
}

.input-login {
  border: 1px solid #545454;
  border-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
}

.input-login-password,
.input-login-password:focus {
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-left: 1px solid #545454 !important;
  border-right: none !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
}

.input-login-button {
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-right: 1px solid #545454 !important;
  border-left: none;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
  padding: 0.32rem 0.75rem !important;
}

.form-control-login:-webkit-autofill,
.form-control-login:-webkit-autofill:hover,
.form-control-login:-webkit-autofill:focus,
.form-control-login:-webkit-autofill:active .input-login:-webkit-autofill,
.input-login:-webkit-autofill:hover,
.input-login:-webkit-autofill:focus,
.input-login:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  border: 1px solid #545454 !important;
  font-family: "Roboto-Regular" !important;
}

.input-login-password:-webkit-autofill,
.input-login-password:-webkit-autofill:hover,
.input-login-password:-webkit-autofill:focus,
.input-login-password:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-left: 1px solid #545454 !important;
  border-right: none !important;
  font-family: "Roboto-Regular" !important;
}

.input-login-button:hover,
.input-login-button:focus {
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-right: 1px solid #545454 !important;
  border-left: none;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
}

.input-login::placeholder {
  color: #9f9f9f !important;
}

.input-login:-ms-input-placeholder {
  color: #9f9f9f !important;
}

.input-login::-ms-input-placeholder {
  color: #9f9f9f !important;
}

.input-login-icon {
  width: 22px !important;
}

.input-login-icon-closed {
  width: 18px !important;
  margin-right: 2px !important;
}

.div-login {
  background-color: #f2f2f2;
  max-width: 500px;
  border-radius: 20px;
}

.btn-login {
  background-color: #858585;
  color: #f2f2f2;
  font-family: "Roboto-Regular";
  font-size: 0.85rem;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-login:hover {
  background-color: #311def;
  color: #f2f2f2;
}

.btn-login:active {
  background-color: #311def;
  color: #f2f2f2;
}

.sin-espacios {
  height: 0px !important;
}

.input-group-append.login {
  background-color: transparent !important;
}

.img-login {
  width: 100%;
  max-width: 250px;
}
