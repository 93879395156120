.container-calendar {
    background-color: #f2f2f2;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 18px 9px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 0px 18px 9px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 0px 18px 9px rgba(0, 0, 0, 0.22);
}

.container-calendar > p {
    font-size: 9px !important;
    color: #242422 !important;
    font-family: 'Roboto-Regular' !important;
    text-align: center !important;
    margin-bottom: 0px !important;
}

.DayPicker-wrapper {
    padding-bottom: 0px !important;
}

.DayPicker-wrapper:focus,
.DayPicker-Day:focus,
.DayPicker-NavButton:focus {
    outline: none !important;
}

.DayPicker-Caption {
    margin-bottom: 0px !important;
    font-family: 'Roboto-Regular' !important;
    color: #242422 !important;
    text-align: center !important;
}

.DayPicker-Month {
    border-collapse: separate !important;
    border-spacing: 8px 5px !important;
    margin-top: 2px !important;
}

.DayPicker-Weekday {
    height: 19px !important;
    max-height: 19px !important;
    width: 21px !important;
    max-width: 21px !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 9px !important;
    color: #242422 !important;
}

.DayPicker-Day {
    background-color: #e3e3e3 !important;
    height: 19px !important;
    max-height: 19px !important;
    width: 21px !important;
    max-width: 21px !important;
    border-radius: 5px !important;
    text-align: center !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 9px !important;
    color: #242422 !important;
}
.DayPicker-Day.DayPicker-Day--today {
    font-family: 'Exo2-Bold' !important;
    color: #000 !important;
}

.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected,
.DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected {
    background-color: #4545f4 !important;
    color: #ffffff !important;
}

.DayPicker-Day.DayPicker-Day--selected {
    background-color: rgb(69, 69, 244, 0.5) !important;
    color: #ffffff !important;
}

.DayPicker-Day:hover {
    background-color: #d9d9d9 !important;
    cursor: pointer !important;
}

.DayPicker-Day.DayPicker-Day--selected:hover {
    background-color: #1a11d1 !important;
    color: #ffffff !important;
}

.DayPicker-Day.DayPicker-Day--outside {
    background-color: #f2f2f2 !important;
}

.DayPicker-NavButton {
    top: 0.2rem !important;
}

.DayPicker-NavButton--prev {
    left: 20px !important;
}

.DayPicker-NavButton--next {
    right: 20px !important;
}

.container-horas {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Roboto-Regular' !important;
    color: #242422;
}

.container-horas > .col-2 > .imagen-titulo {
    height: 28px;
    width: 28px;
    border-radius: 5px;
}

.MuiSlider-rail {
    width: calc(100% - 2px) !important;
    height: 4px !important;
    border-radius: 2px !important;
    background-color: transparent !important;
    border: 1px solid #242422 !important;
}

.MuiSlider-track {
    height: 6px !important;
    background-color: #242422 !important;
}

.MuiSlider-thumb {
    margin-top: -3px !important;
    height: 12px !important;
    width: 12px !important;
    background-color: #1a11d1 !important;
}

.MuiSlider-valueLabel > span {
    font-family: 'Roboto-Regular' !important;
    font-size: 9px !important;
    background-color: #1a11d1 !important;
}

.dropdown-menu.calendario-drop {
    left: auto !important;
    margin: 0.125rem 0.4rem 0 !important;
}

.button-filtrar {
    font-family: 'Roboto-Regular' !important;
    font-size: 12px !important;
    background-color: transparent !important;
    color: #4545f4 !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
    margin-bottom: 10px !important;
}

.button-filtrar:hover {
    background-color: #4545f4 !important;
    color: #ffffff !important;
}
