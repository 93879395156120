.menu-logo {
    width: 150px;
}

@media (min-width: 600px) {
    .MuiToolbar-gutters {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.MuiButtonGroup-contained {
    max-height: 30px !important;
}

.MuiToolbar-gutters {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
    border-right: none !important;
}

.btn-navbar {
    font-family: 'Roboto-Regular' !important;
    /* color: #ffffff !important; */
    font-size: 10px !important;
    height: 30px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    background-color: #242422 !important;
}

.MuiDrawer-paperAnchorDockedRight {
    background-color: #242422 !important;
}

/* .MuiSvgIcon-root {
    color: #f2f2f2;
} */

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    align-items: center !important;
    justify-self: center !important;
}

.MuiListItemIcon-root {
    min-width: 0px !important;
    display: inline !important;
}

.boton-actualizar .MuiButton-startIcon {
    display: block !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
}

.circle-user {
    border-radius: 35px;
    height: 67px;
    width: 67px;
}

.icon-user {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    width: 70px;
    height: auto;
}

.icon-user-right {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    width: 70px;
    height: auto;
}

.icon-other {
    width: 35px;
    height: auto;
}

.back-icon {
    background-color: #545454;
}

.Layout-drawerClose-8 {
    width: 100px !important;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight,
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    margin-top: 64px !important;
    background-color: #242422;
}

.text-layout {
    font-family: 'Roboto-Regular' !important;
    color: white;
    font-size: medium;
}

.MuiIcon-colorSecondary {
    color: white;
    background-color: white;
}

.MuiAccordion-root > .MuiCollapse-container.MuiCollapse-entered {
    margin-left: 0px;
    border-left: none;
}

.MuiCollapse-container.MuiCollapse-entered {
    margin-left: 30px;
    border-left: 5px solid #4545f4;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}

.sub-admin {
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 3px;
    left: 5px;
    top: 18px;
}

.icon-arrow {
    width: 20px;
}

.MuiToolbar-regular {
    max-height: 64px;
}

@media (min-width: 0px) and (max-width: 770px) {
    .Layout-drawerClose-8 {
        width: 57px !important;
    }

    .circle-user {
        border-radius: 20px;
        margin-left: -5px;
        height: 37px;
        width: 37px;
    }

    .icon-user {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        width: 50px;
        height: auto;
    }

    .icon-user-right {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        width: 50px;
        height: auto;
    }

    .icon-other {
        margin-left: -5px;
        width: 40px;
        height: auto;
    }
}

.btn-drop-icon {
    border-radius: 4px !important;
    right: 0px !important;
}

.Layout-sectionDesktop-12 button {
    font-size: 10px !important;
}

.Layout-sectionDesktop-12 {
    height: auto !important;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.9) !important;
    margin-top: 64px !important;
}

.selected-list {
    background-color: #b20000 !important;
    border-radius: 5px !important;
}

.px-2-5 {
    padding-left: 0.6rem !important;
    padding-right: 0.5rem !important;
}

.pl-2-5 {
    padding-left: 0.7rem !important;
}

.select-cliente {
    height: 30px !important;
    font-size: 10px !important;
    background-color: #4545f4 !important;
}

.select-cliente > button {
    height: 30px !important;
}

.select-cliente > .MuiFilledInput-input {
    padding: 0px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    background-color: transparent !important;
}

.select-cliente > svg {
    display: none !important;
}

.input-layout:disabled {
    border-radius: 15px !important;
    background-color: #e6e6e6 !important;
}

.input-layout {
    border-radius: 15px !important;
    background-color: #f2f2f2 !important;
}

.mw-115 {
    min-width: 115px !important;
}

.mw-140 {
    min-width: 150px !important;
}

.opt-layout {
    font-size: 10px !important;
}

.select-cliente .img-layout {
    display: block !important;
}

.MuiMenu-list .img-layout {
    display: none !important;
}

.MuiMenu-list .opt-layout {
    font-size: 12px !important;
}

.MuiListItem-button:hover span {
    font-family: 'Roboto-Bold' !important;
}

.MuiAutocomplete-option {
    color: #545454 !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 0.75rem !important;
}

.MuiAutocomplete-option-title {
    color: #000000 !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 15px !important;
}

.MuiAutocomplete-option-subtitle {
    color: #545454 !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 13px !important;
}

.MuiAutocomplete-paper {
    margin: auto !important;
}

.btn-left-calendar.MuiButton-root {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.btn-right-calendar.MuiButton-root {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
